import {  LOAD_DATA, LOAD_CHILDREN, UPDATE_FAVORITE, LOAD_FOOTER_INFO } from '../contextTypes'

export default (state, action) => {
    switch (action.type) {
        case LOAD_DATA:
            return {
                ...state,
                data: action.payload.data,
                facets: action.payload.facets,
                productCategory: action.payload.productCategory,
                currentPage: action.payload.currentPage,
                appliedFacets: action.payload.appliedFacets,
                count: action.payload.count,
                myTrends: action.payload.myTrends
            }
        case LOAD_CHILDREN:
            return {
                ...state,
                dataChildren: action.payload.dataChildren
            }
        case UPDATE_FAVORITE:
            return {
                ...state,
                data: action.payload.data
            }
        case LOAD_FOOTER_INFO:
            return {
                ...state,
                footerInfo: action.payload.footerInfo
            }
        default:
            return state;
    }
}