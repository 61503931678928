import React, {useState, useEffect, useContext} from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DataContext from '../../context/data/dataContext'

const Footer = () => {

    const dataContext = useContext(DataContext)

    const [footerInfo, setFooterInfo] = useState({})

    useEffect(() => {
        if (!dataContext.footerInfo.photoCount)
            dataContext.loadFooterInfo();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setFooterInfo(dataContext.footerInfo);
    }, [dataContext.footerInfo]);


    return (
        <footer className="footer">
                <Row className="no-gutters">
                    <Col xs={4} className="footer-left">&copy; COPYRIGHT 2020<br/>EdizioniAF</Col>
                    <Col xs={4} className="footer-center">{footerInfo.photoCount} images / {footerInfo.photoCountLastWeek} new stock images added this week</Col>
                    <Col xs={4} className="footer-right"><a href="https://www.arstrends.com/contact-us">Contact us</a></Col>
                </Row>
            </footer>
    )
}

export default Footer
