import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import logo from '../../images/logo.png'

const MenuDetail = (props) => {

    const {showDetailPhotoHandler} = props;

    return (
        <Navbar bg="secondary" expand="sm">
            <Navbar.Brand href="#home"><img src={logo} alt="ArsTrends" width={"40px"} /></Navbar.Brand>
            <Nav.Link className="back-to-grid" onClick={() => showDetailPhotoHandler()}><FontAwesomeIcon icon={faArrowLeft} /></Nav.Link>
        </Navbar>
    )
}

export default MenuDetail
