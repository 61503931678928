import React, {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'

const AppliedFilters = (props) => {

    const {toggleMobileFacetsHandler, appliedFacets, optionSelectorHandler} = props;

    const [renderedAppliedFacets, setRenderedAppliedFacets] = useState([]);

    useEffect(() => {

        const removeAppliedFacet = async(appliedFacetIndex) => {
            optionSelectorHandler(appliedFacets[appliedFacetIndex].facetCode, appliedFacets[appliedFacetIndex]);
        }

        let tmp = [];
        for (const [index, appliedFacet] of appliedFacets.entries()) {
            tmp.push(<li key={index} onClick={()=>removeAppliedFacet(index)}>{appliedFacet.label} <FontAwesomeIcon icon={faTimesCircle} /></li>);
        }
        setRenderedAppliedFacets(tmp);
    }, [appliedFacets, optionSelectorHandler])


    return (
        <div className="applied-facets">
            <Button className="mobile-facets-toggle-button" variant="outline-dark" size="sm" onClick={()=>toggleMobileFacetsHandler(true)}><FontAwesomeIcon icon={faSlidersH} /></Button>
            <ul className="p-0">
                {renderedAppliedFacets}
            </ul>
        </div>
    )
}

export default AppliedFilters
