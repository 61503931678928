import React, {useContext, useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {NavLink} from 'react-router-dom'
import UserContext from '../../context/user/userContext'
import LoaderContext from '../../context/loader/loaderContext'
import DataContext from '../../context/data/dataContext'
import { useHistory } from "react-router-dom"
import logo from '../../images/logo.png'
import getTitleFromProductCategory from '../../helpers/MenuHelper'

const Menu = (props) => {

    const {favorites, productCategory} = props;

    const userContext = useContext(UserContext)
    const loaderContext = useContext(LoaderContext)
    const dataContext = useContext(DataContext)

    const [menuExpanded, setMenuExpanded] = useState(false);

    const history = useHistory();

    const logout = async () => {
        loaderContext.showLoader(true);
        window.Intercom('shutdown');
        history.push({
            pathname: '/',
            search: ''
          })    
        await userContext.logout();
        loaderContext.showLoader(false);
    }

    const createLink = async () => {
        let url = dataContext.makeLink();
        await navigator.clipboard.writeText(url)
        alert('Link copied to the browser: \n' + url);
    }

    const goToProductCategory = (productCategory) => {
        setMenuExpanded(false);
        if (!favorites)
            dataContext.loadData(productCategory, 1, true);
        else {
            history.push("/home", {productCategory: productCategory});
            //history.push(`/home#${productCategory}`); funziona, ma mette la hash
        }
    }

    return (
        <Navbar bg="secondary" expand="sm" collapseOnSelect expanded={menuExpanded} onToggle={(expanded)=>setMenuExpanded(expanded)}>
            <div className="menu-mobile-location">{favorites ? "Favorites" : getTitleFromProductCategory(productCategory)}</div>
            <Navbar.Brand href="#home"><img src={logo} alt="ArsTrends" width={"40px"} /></Navbar.Brand>
            <Navbar.Toggle className='order-first' />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="mr-auto">
                    <Nav.Link active={!favorites && productCategory === 'SHOE'}     onClick={() => {goToProductCategory('SHOE')}}>SHOES</Nav.Link>
                    <Nav.Link active={!favorites && productCategory === 'BAG'}      onClick={() => {goToProductCategory('BAG')}}>BAGS</Nav.Link>
                    <Nav.Link active={!favorites && productCategory === 'KID'}      onClick={() => {goToProductCategory('KID')}}>KIDS SHOES</Nav.Link>
                    <Nav.Link active={!favorites && productCategory === 'MATERIAL'} onClick={() => {goToProductCategory('MATERIAL')}}>MATERIALS</Nav.Link>
                </Nav>
                <Nav>
                    {userContext.roles.includes('ROLE_LINK') && (<Nav.Link onClick={createLink}>LINK</Nav.Link>)}
                    <NavLink to='/favorites' className="nav-link">Favorites</NavLink>
                    {/* <Nav.Link onClick={logout}>Logout</Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Menu
