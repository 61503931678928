import React, {useState, useEffect} from 'react'
import Pagination from 'react-bootstrap/Pagination';

const Paginator = (props) => {

    const {count, currentPage, changePageHandler} = props;
    const [paginationJSX, setPaginationJSX] = useState([])
    
    useEffect(() => {

        const DELTA_PAGE = 5;
        const ITEMS_PER_PAGE = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE, 10)

        let tmpJSX = []
        let pagesCount = parseInt(count / ITEMS_PER_PAGE, 10) + (count % ITEMS_PER_PAGE === 0 ? 0 : 1);
        if (currentPage !== 1) {
            tmpJSX.push(<Pagination.First key={"first"} onClick={()=>changePageHandler(1)}/>);
            tmpJSX.push(<Pagination.Prev key={"prev"} onClick={()=>changePageHandler(currentPage - 1)}/>);
        }
        for (let index = currentPage - DELTA_PAGE; index < currentPage + DELTA_PAGE; index ++) {
            if (index >= 1 && index <= pagesCount)
                tmpJSX.push(<Pagination.Item key={index} active={index === currentPage} onClick={()=>changePageHandler(index)}>{index}</Pagination.Item>);
        }
        if (currentPage + DELTA_PAGE < pagesCount) {
            tmpJSX.push(<Pagination.Next key={"next"} onClick={()=>changePageHandler(currentPage + 1)}/>);
            tmpJSX.push(<Pagination.Last key={"last"} onClick={()=>changePageHandler(pagesCount)}/>);
        }
        setPaginationJSX(tmpJSX)

    }, [count, currentPage, changePageHandler])

    return (
        <div className="paginator d-flex flex-wrap">
            <Pagination>
                {paginationJSX}
            </Pagination>
        </div>
    )
}

export default Paginator
