import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/pages/LoginPage'
import HomePage from './components/pages/HomePage'
import FavoritesPage from './components/pages/FavoritesPage'
import SSOPage from './components/pages/SSOPage'
import PrivateRoute from './helpers/PrivateRoute'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import UserState from './context/user/UserState'
import LoaderState from './context/loader/LoaderState'
import DataState from './context/data/DataState'
import FavoriteState from './context/favorite/FavoriteState'
import Loader from './components/loader/Loader'


function App() {
  return (
    <LoaderState>
      <UserState>
        <DataState>
          <FavoriteState>
            <Router basename="/app">
              <Switch>
                <Route        exact path='/sso'       component = {SSOPage}      />
                <Route        exact path='/'          component = {LoginPage}      />
                <PrivateRoute exact path='/home'      component = {HomePage}       />
                <PrivateRoute exact path='/favorites' component = {FavoritesPage}  />
              </Switch>
              <Loader />
            </Router>
          </FavoriteState>
        </DataState>
      </UserState>
    </LoaderState>
  );
}

export default App;
