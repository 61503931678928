import React, {useState, useEffect, useContext} from 'react'
import { Redirect } from "react-router-dom";
import axios from 'axios'
import qs from 'qs'
import UserContext from '../../context/user/userContext'
import LoaderContext from '../../context/loader/loaderContext'
import { Fragment } from 'react'
import {useLocation} from "react-router-dom";

const SSO = () => {

    const userContext = useContext(UserContext);
    const loaderContext = useContext(LoaderContext);
    const [redirectToHome, setRedirectToHome] = useState('nonsettato');

    const location = useLocation();

    let queryStringParams = qs.parse(location.search.substring(1))
    console.log("queryStringParams", queryStringParams)

    useEffect(() => {
        console.log("sono in SSO")
        async function sso() {
            if (location.search !== '') {
                //let queryString = qs.parse(location.search.substring(1))
                loaderContext.showLoader(true);
                const res = await userSSOCall({data: queryStringParams.data});
                console.log("mostro res", res);
                if (res.success) {
                    console.log("loggato");
                    
                    console.log("OK NAVIGO ORA a " + queryStringParams.cburl);

                    const userInfo = await userInfoCall();
                    if (userInfo.email) {
                        userContext.updateUser(userInfo.email, userInfo.auths);
                        setRedirectToHome(queryStringParams.cburl);
                    }

                    //console.log("OK NAVIGO ORA a " + process.env.REACT_APP_BASE_URL + '/login/ssoRedir?cburl=' + queryStringParams.cburl);
                       
                    //window.location.href = process.env.REACT_APP_BASE_URL + '/login/ssoRedir?cburl=' + queryStringParams.cburl
                } else {
                    console.log("non loggato");
                    window.location.href = queryStringParams.wperr
                }
                loaderContext.showLoader(false);
            }
        }
        sso();
        // eslint-disable-next-line
    }, []);

    const axiosTransport = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL
    })

    const userSSOCall = async (payload) => {

        try {
            const res = await axiosTransport({
                method: 'get',
                url: '/login/sso?' + qs.stringify(payload)
            });

            //console.log('getUserInfo result:');
            //console.log(JSON.stringify(res.data));
            return res.data;
        } catch(e) {
            console.log('sso failed:');
            console.error(e)
            return {}
        }

    }

    const userInfoCall = async () => {

        try {
            const res = await axiosTransport({
                method: 'get',
                url: '/profile/getUserInfo'
            });

            //console.log('getUserInfo result:');
            //console.log(JSON.stringify(res.data));
            return res.data;
        } catch(e) {
            console.log('getUserInfo failed:');
            return {}
        }

    }

    
    return (
        (redirectToHome !== 'nonsettato') ? <Redirect to={{pathname: '/home', search: redirectToHome}} /> : <></>
    )
}

export default SSO
