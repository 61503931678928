import React, { useReducer, useContext } from 'react';
import { LOAD_DATA, LOAD_CHILDREN, UPDATE_FAVORITE, LOAD_FOOTER_INFO } from '../contextTypes'
import DataReducer from './dataReducer'
import DataContext from './dataContext'
import axios from 'axios'
import LoaderContext from '../loader/loaderContext'
import UserContext from '../user/userContext'
import * as Common from '../commonStateLogic'
import qs from 'qs'

const DataState = props => {

  const loaderContext = useContext(LoaderContext);
  const userContext = useContext(UserContext);

  const axiosTransport = axios.create({
      withCredentials: true,
      baseURL: process.env.REACT_APP_BASE_URL
  })

  const getDefaultProductCategory = () => {

    if (userContext.roles.includes('ROLE_SHOES'))
      return 'SHOE';

    for (let index = 0; index < userContext.roles.length; index++) {
      if ((userContext.roles[index] !== 'ROLE_USER') && (userContext.roles[index] !== 'ROLE_TRIAL'))
        return userContext.roles[index].substr(0, userContext.roles[index].length -1).replace('ROLE_', '');
    }
  }

  //le appliedFacets sono oggetti così costituiti: {facetCode, label (che sarebbe la options.label), name (che sarebbe option.name)}
  //la categoria di default viene settata alla prima loadData
  const initialState = {
    data: [],
    facets: {},
    appliedFacets: [],
    productCategory: undefined,
    currentPage: 1,
    dataChildren: [],
    count: 0,
    myTrends: {},
    footerInfo: {}
  }

  const [state, dispatch] = useReducer(DataReducer, initialState);
  
  /**
   * Questo metodo si occupa di ricalcolare le facet applicate dopo aver invocato la API
   * @param {*} facets 
   */
  const buildAppliedFacetsFromAPI = (facets) => {
    let newAppliedFacets = [];
    // eslint-disable-next-line
    for (let [key, facet] of Object.entries(facets)) {
      // eslint-disable-next-line
      for (const [index, option] of facet.options.entries()) {
        if (option.applied) {
          newAppliedFacets.push({facetCode: facet.facetCode, label: option.label, name: option.name});
        }
      }
    }
    return newAppliedFacets;
  }

  /**
   * Questo metodo costruisce la queryString delle facets
   */
  const buildFacetsQueryString = () => {
    let tmpAF = {};
    for (let index = 0; index < state.appliedFacets.length; index++) {
      let appliedFacet = state.appliedFacets[index];
      if (!tmpAF[appliedFacet.facetCode]) {
        tmpAF[appliedFacet.facetCode] = [];
      }
      tmpAF[appliedFacet.facetCode].push(appliedFacet.name);
    }
    let facetQS = '';
    for (let [facetCode, arrOfLabels] of Object.entries(tmpAF)) {
      let optionsString = '';
      // eslint-disable-next-line
      for (const [index, optionName] of arrOfLabels.entries()) {
        optionsString += '|' + encodeURIComponent(optionName);
      }
      facetQS += '&facets.' + facetCode + '=' + optionsString;
    }
    return facetQS;
  }

  /**
   * Ricarica tutti i dati
   * @param {*} productCategory 
   * @param {*} page 
   */
  const loadData = async (productCategory = state.productCategory, page = 1, resetAppliedFacets = false) => {

    loaderContext.showLoader(true);
    //url con fotofiglie:
    //url: "/api/photos?page=1&order=photoCallDate&productCategory=SHOE&facets.photoCallCategory=|COLLECTION&perPage=35&_=1592388194043"

    if (state.productCategory === undefined)
      productCategory = getDefaultProductCategory();

    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/photos?order=photoCallDate&productCategory=${productCategory}${!resetAppliedFacets ? buildFacetsQueryString() : ""}&page=${page}&perPage=${process.env.REACT_APP_ITEMS_PER_PAGE}&_=${new Date().getTime()}`
      });

      dispatch({
          type: LOAD_DATA,
          payload: {
            data: res.data.data,
            facets: res.data.facets,
            appliedFacets: buildAppliedFacetsFromAPI(res.data.facets),
            productCategory: productCategory,
            currentPage: page,
            count: res.data.count,
            myTrends: res.data.myTrends
          }
      })
    } catch(e) {
      console.error(`error while loading data for productCategory ${productCategory} with state ${JSON.stringify(state)}`, e);
    }

    loaderContext.showLoader(false);

  }

  /**
   * Aggiorna le facet applicate e poi carica tutti i dati
   * @param {*} option 
   * @param {*} isAdded 
   */
  const toggleFacet = (facetCode, option) => {
    let addFacet = true;
    for (let index = 0; index < state.appliedFacets.length; index++) {
      let appliedFacet = state.appliedFacets[index];
      if (appliedFacet.facetCode === facetCode && appliedFacet.name === option.name) {
        state.appliedFacets.splice(index, 1);
        addFacet = false;
        break;
      }
    }
    if (addFacet) {
      state.appliedFacets.push({facetCode: facetCode, label: option.label, name: option.name});
    }
    //sarà loadData a comunicare le nuove facet, ma resettiamo la pagina
    loadData(state.productCategory);
  }

  /**
   * Gestisce il toggle di preferito per una photo. Lo fa partendo da una shallow copy dell'array data e sostituendo un nuovo oggetto (vedere appunti shallow e destructuring)
   * @param {*} photo 
   */
  const toggleFavorite = async (photo) => {

    loaderContext.showLoader(true);

    await Common.toggleFavoriteServerCall(photo, getMyTrendIdKey(), axiosTransport);

    let newData = Common.getUpdatedDataArray(state.data, photo, getMyTrendIdKey());

    dispatch({
        type: UPDATE_FAVORITE,
        payload: {
          data: newData
        }
    })
    
    loaderContext.showLoader(false);

  }

  const changePage = (page) => {
    loadData(state.productCategory, page);
  }

  /**
   * Carica eventuali foto figlie
   * @param {*} photoId 
   */
  const loadChildren = async (photoId) => {

    loaderContext.showLoader(true);

    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/photos/listDescendantPhotosByFather?fatherId=${photoId}`
      });

      //console.log(`Found ${res.data.data.length} children from API`);

      dispatch({
          type: LOAD_CHILDREN,
          payload: {
            dataChildren: res.data.data
          }
      })
    } catch(e) {
      console.error(`error while loading children for photoId ${photoId}`, e);
    }

    loaderContext.showLoader(false);

  }

  /**
   * Questo metodo carica i data a partire da un link che rappresenta una serie di filtri da applicare
   * @param {*} link 
   */
  const loadMagicLink = (link) => {
    //TODO implementare correttamente
    console.log("malink method");
    let fakeFacets = [];
    fakeFacets.push({facetCode: 'photoCallCategory', name: 'RETAIL', label: 'Retail and other'});
    fakeFacets.push({facetCode: 'photoCallCity', name: 'Mexico', label: 'Mexico test'});
    let fakeProductCategory = 'SHOE';

    // eslint-disable-next-line
    for (const [index, fakeFacet] of fakeFacets.entries()) {
      state.appliedFacets.push({facetCode: fakeFacet.facetCode, label: fakeFacet.label, name: fakeFacet.name});
    }

    //sarà loadData a comunicare le nuove facet, ma resettiamo la pagina
    loadData(fakeProductCategory);
  }


  /**
   * Carica i dati partendo da un link con queryString
   * @param {*} qsString 
   */
  const loadByQS = async(qsString) => {

    loaderContext.showLoader(true);

    let parsedQs = qs.parse(qsString)

    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/photos${qsString}&order=photoCallDate&page=1&perPage=${process.env.REACT_APP_ITEMS_PER_PAGE}&_=${new Date().getTime()}`
      });

      dispatch({
          type: LOAD_DATA,
          payload: {
            data: res.data.data,
            facets: res.data.facets,
            appliedFacets: buildAppliedFacetsFromAPI(res.data.facets),
            productCategory: parsedQs.productCategory,
            currentPage: 1,
            count: res.data.count,
            myTrends: res.data.myTrends
          }
      })
    } catch(e) {
      console.error(`error while loading data for QS ${qs}`, e);
    }
    loaderContext.showLoader(false);
  }

  /**
   * Metodo di utilità per ottenere la stringa che fa da chiave per il myTrendId
   */
  const getMyTrendIdKey = () => {
    return Object.keys(state.myTrends)[0];
  }

  /**
   * Questo metodo crea un link utilizzabile come queryString per interrogare con i filtri
   */
  const makeLink = () => {
    return encodeURI(process.env.REACT_APP_FRONTEND_BASE_URL + '?productCategory=' + state.productCategory + buildFacetsQueryString());
  }

  const loadFooterInfo = async () => {

    //console.log("Loading footer info");

    //commentati i loader
    //loaderContext.showLoader(true);

    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/homepage/footerInfo`
      });

      dispatch({
          type: LOAD_FOOTER_INFO,
          payload: {
            footerInfo: res.data
          }
      })
    } catch(e) {
      console.error(`error while loading footer info`, e);
    }

    //loaderContext.showLoader(false);

  }

  /**
   * Spedisce chiamata al server per il tracking dell'azione
   * @param {*} photo 
   */
  const trackDetail = async (photo) => {
    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/photos/detail/${photo.id}`
      });

    } catch(e) {
      console.error(`error while tracking photo detail ${JSON.stringify(photo)}`, e);
    }
  }
    

  return (
    <DataContext.Provider
      value={{
        data: state.data,
        facets: state.facets,
        productCategory: state.productCategory,
        appliedFacets: state.appliedFacets,
        mainCategory: state.mainCategory,
        currentPage: state.currentPage,
        dataChildren: state.dataChildren,
        count: state.count,
        myTrends: state.myTrends,
        footerInfo: state.footerInfo,
        loadData,
        toggleFavorite,
        toggleFacet,
        loadChildren,
        changePage,
        getMyTrendIdKey,
        loadFooterInfo,
        loadMagicLink: loadMagicLink,
        makeLink: makeLink,
        loadByQS: loadByQS,
        trackDetail: trackDetail
      }}
    >
      {props.children}
    </DataContext.Provider>
  );

}

export default DataState