import React, {useState, useContext, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Menu from '../menu/Menu'

import PhotoGrid from '../photos/PhotoGrid';
import Paginator from '../paginator/Paginator';
import Footer from '../footer/Footer';

import DataContext from '../../context/data/dataContext'
import FavoriteContext from '../../context/favorite/favoriteContext'

import Detail from '../detail/Detail';


const FavoritesPage = () => {

    const [photoDetail, setPhotoDetail] = useState({});//è la foto di dettaglio
    const [photoList, setPhotoList] = useState([])

    const dataContext = useContext(DataContext)
    const favoriteContext = useContext(FavoriteContext)
    
    
    useEffect(() => {
        favoriteContext.loadData();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setPhotoList(favoriteContext.data)
    }, [favoriteContext.data])
    
    const changePageHandler = (page) => {
        favoriteContext.changePage(page);
    }

    /**
     * 
     * @param {*} photo : se presente è la foto da visualizzare nel dettaglio, altrimenti se vuoto significa tornare alla grid view
     */
    const showDetailPhoto = async (photo) => {
        if (!photo) {
            setPhotoDetail({});
        } else {
            dataContext.trackDetail(photo);
            await dataContext.loadChildren(photo.id);
            setPhotoDetail(photo);
        }
    }


    const toggleFavoriteHandler = async (photo) => {
        favoriteContext.toggleFavorite(photo, dataContext.getMyTrendIdKey());
    }

    
    return (
        photoDetail.id === undefined ?

        <Container fluid className="p-0 home-page h-100 d-flex flex-column">
            <Menu favorites={true} />
            <div className="homepage-content d-flex">
                <div className="grid-container">
                    <PhotoGrid photoList={photoList} onFavoritePage={true} showDetailPhotoHandler={showDetailPhoto} trialView={false} toggleFavoriteHandler={toggleFavoriteHandler} />
                    <Paginator count={favoriteContext.count} currentPage={favoriteContext.currentPage} changePageHandler={changePageHandler} /> 
                </div>
            </div>
            <Footer />
        </Container>

        :

        <Detail photoList={photoList} photoDetail={photoDetail} setPhotoDetail={setPhotoDetail} toggleFavoriteHandler={toggleFavoriteHandler} showDetailPhoto={showDetailPhoto} photoDetailChildren={dataContext.dataChildren}/>
    )
       
}

export default FavoritesPage
