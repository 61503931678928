import {  LOAD_DATA, LOAD_CHILDREN, UPDATE_FAVORITE } from '../contextTypes'

export default (state, action) => {
    switch (action.type) {
        case LOAD_DATA:
            return {
                ...state,
                data: action.payload.data,
                currentPage: action.payload.currentPage,
                count: action.payload.count,
                myTrends: action.payload.myTrends
            }
        case LOAD_CHILDREN:
            return {
                ...state,
                dataChildren: action.payload.dataChildren
            }
        case UPDATE_FAVORITE:
            return {
                ...state,
                data: action.payload.data
            }
        default:
            return state;
    }
}