import React, { useReducer, useContext } from 'react';
import { LOAD_DATA, LOAD_CHILDREN, UPDATE_FAVORITE } from '../contextTypes'
import FavoriteReducer from './favoriteReducer'
import FavoriteContext from './favoriteContext'
import axios from 'axios'
import LoaderContext from '../loader/loaderContext'
import DataContext from '../data/dataContext'
import * as Common from '../commonStateLogic'

const FavoriteState = props => {

  const loaderContext = useContext(LoaderContext);
  const dataContext = useContext(DataContext);

  const axiosTransport = axios.create({
      withCredentials: true,
      baseURL: process.env.REACT_APP_BASE_URL
  })

  const initialState = {
    data: [],
    currentPage: 1,
    dataChildren: [],
    count: 0,
    myTrends: {}
  }

  const [state, dispatch] = useReducer(FavoriteReducer, initialState);
  
  

  /**
   * Ricarica tutti i dati
   */
  const loadData = async (page = 1) => {

    const myTrendId = Object.keys(dataContext.myTrends)[0];

    loaderContext.showLoader(true);
    //url con fotofiglie:
    //url: "/api/photos?page=1&order=photoCallDate&productCategory=SHOE&facets.photoCallCategory=|COLLECTION&perPage=35&_=1592388194043"
    //http://localhost:8080/app/api/myTrendPhotos?page=1&order=photoCallDate&perPage=35&myTrendId=6418&_=1592811566486


    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/myTrendPhotos?order=photoCallDate&myTrendId=${myTrendId}&page=${page}&perPage=${process.env.REACT_APP_ITEMS_PER_PAGE}&_=${new Date().getTime()}`
      });

      dispatch({
          type: LOAD_DATA,
          payload: {
            data: res.data.data,
            currentPage: page,
            count: res.data.count,
            myTrends: res.data.myTrends
          }
      })
    } catch(e) {
      console.error(`error while loading favorite data for myTrendId ${myTrendId} with state ${JSON.stringify(state)}`, e);
    }

    loaderContext.showLoader(false);

  }


  const changePage = (page) => {
    loadData(page);
  }

  /**
   * Carica eventuali foto figlie
   * @param {*} photoId 
   */
  const loadChildren = async (photoId) => {

    loaderContext.showLoader(true);

    try {
      // eslint-disable-next-line
      const res = await axiosTransport({
          method: 'get',
          url: `/api/photos/listDescendantPhotosByFather?fatherId=${photoId}`
      });

      //console.log(`Found ${res.data.data.length} children from API`);

      dispatch({
          type: LOAD_CHILDREN,
          payload: {
            dataChildren: res.data.data
          }
      })
    } catch(e) {
      console.error(`error while loading children for photoId ${photoId}`, e);
    }

    loaderContext.showLoader(false);

  }

  /**
   * Gestisce il toggle di preferito per una photo. Lo fa partendo da una shallow copy dell'array data e sostituendo un nuovo oggetto (vedere appunti shallow e destructuring)
   * @param {*} photo 
   */
  const toggleFavorite = async (photo, myTrendIdKey) => {

    let isAdd = Object.entries(photo.myTrends).length === 0;

    loaderContext.showLoader(true);

    await Common.toggleFavoriteServerCall(photo, myTrendIdKey, axiosTransport);

    //facciamo questo giro perchè nella favorite grid posso solo rimuovere preferiti e questo fa si che venga proprio rimossa fisicamente dall'array
    //se faccio una add è perchè sono nel dettaglio proveniente da un favorite e ho cliccato due volte il cuoricino quindi devo fisicamente aggiungere
    //la foto all'array (viene messa in coda all'array)
    let tmpArray = [...state.data]

    if (isAdd) {
      tmpArray.push(photo)
    }

    let newData = Common.getUpdatedDataArray(tmpArray, photo, myTrendIdKey, !isAdd);

    dispatch({
        type: UPDATE_FAVORITE,
        payload: {
          data: newData
        }
    })
    
    loaderContext.showLoader(false);

  }
    

  return (
    <FavoriteContext.Provider
      value={{
        data: state.data,
        currentPage: state.currentPage,
        dataChildren: state.dataChildren,
        count: state.count,
        myTrends: state.myTrends,
        toggleFavorite,
        loadData,
        loadChildren,
        changePage
      }}
    >
      {props.children}
    </FavoriteContext.Provider>
  );

}

export default FavoriteState