import React from 'react'
import Photo from './Photo'
import { Fragment } from 'react'

//il re-render avviene su cambio stato ovvero props oppure state: https://lucybain.com/blog/2017/react-js-when-to-rerender/#:~:text=A%20re%2Drender%20can%20only,should%20re%2Drender%20the%20component.
const PhotoGrid = (props) => {

    const {photoList, showDetailPhotoHandler, trialView, toggleFavoriteHandler, onFavoritePage} = props;

    const photos = [];

    if (photoList) {
        //console.log(`PL: ${JSON.stringify(photoList)}`);
        for (const [index, photo] of photoList.entries()) {
            photos.push(<Photo key={index} photo={photo} showDetailPhotoHandler={showDetailPhotoHandler} trialView={trialView} toggleFavoriteHandler={toggleFavoriteHandler}/>);
        }
    }

    return (
        <Fragment>
        {trialView && (
            <div className="trial-banner"><a href="https://www.arstrends.com"><img src="https://www.arstrends.com/service/img/arstrends/trialbanner-logged.jpg" alt="" /></a></div>
        )}
        {photoList.length > 0 && (
            <div className="photo-grid">
                {photos}
            </div>
        )}
        {photoList.length === 0 && onFavoritePage && (
            <div className="photo-grid-empty">
                <p>Add a favorite image clicking on "favorites" icon</p>
            </div>
        )}
        </Fragment>
    )
}

export default PhotoGrid
