import React, {useState, useEffect} from 'react'

const PhotoDetailChildren = (props) => {

    const {photoDetailChildren, setDetailViewWithChildHandler} = props;

    const [photoDetailChildrenJSX, setPhotoDetailChildrenJSX] = useState([]);

    useEffect(() => {

        let arr = [];
        for (const [index, photo] of photoDetailChildren.entries()) {
            arr.push(<img src={process.env.REACT_APP_PHOTO_BASE_URL + photo.thumbURL} key={index} onClick={()=>setDetailViewWithChildHandler(photoDetailChildren[index])} alt=""/>);
        }
        setPhotoDetailChildrenJSX(arr);
    }, [photoDetailChildren, setDetailViewWithChildHandler])
    

    return (
        <div className="photo-detail-children" style={photoDetailChildren.length > 0 ? {} : {width: "0px", height: "0px"}}>
            <div className="scroller" style={{width: (photoDetailChildren.length * 150) + "px"}}>
                {photoDetailChildrenJSX}
            </div>
        </div>  
    )
}

export default PhotoDetailChildren
