import React, { useReducer } from 'react';
import { UPDATE_USER, LOGOUT } from '../contextTypes'
import UserReducer from './userReducer'
import UserContext from './userContext'
import axios from 'axios'

const UserState = props => {

  

    const axiosTransport = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL
    })

    const initialState = {
      username: '',
      roles: []
    }

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const updateUser = (username, roles) => {
        dispatch({
            type: UPDATE_USER,
            payload: {
                username: username,
                roles: roles
            }
        })
    }

    const isAdmin = () => {
        return state.roles.includes("ROLE_ADMIN")
    }

    const logout = async () => {
      
        // eslint-disable-next-line
        const res = await axiosTransport({
            method: 'get',
            url: '/logout'
        });

        dispatch({
            type: LOGOUT,
            payload: {}
        })

    }


    return (
        <UserContext.Provider
          value={{
            username: state.username,
            roles: state.roles,
            updateUser: updateUser,
            isAdmin: isAdmin,
            logout: logout
          }}
        >
          {props.children}
        </UserContext.Provider>
      );

}

export default UserState