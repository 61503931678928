import React, {useState, useEffect} from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'


const Facet = (props) => {

    const SHOW_FILTER_WHEN_NUM_OF_ITEMS = 5;

    const {facetIndex, title, facetCode,  items, optionSelectorHandler, expanderHandler, showBody} = props;

    const [filterValue, setFilterValue] = useState('');
    const [renderedItems, setRenderedItems] = useState([]);

    useEffect(() => {
        createRenderableItemLines(items);
        // eslint-disable-next-line
    }, [items]);//messo il [] per runnare alla componentDidMount

    const showFilter = (items.length > SHOW_FILTER_WHEN_NUM_OF_ITEMS);

    const toggleFilter = async(index) => {
        optionSelectorHandler(facetCode, items[index]);
    }

    const filterItems = (filterText) => {
        let filteredItems = [];
        // eslint-disable-next-line
        for (const [index, item] of items.entries()) {
            
            if (!item.label.toLowerCase().includes(filterText.toLowerCase())) {
                let newItem = {hide: true}
                Object.assign(newItem, item);
                filteredItems.push(newItem);
            } else {
                filteredItems.push(item);
            }
            
        }
        createRenderableItemLines(filteredItems);
    }

    const createRenderableItemLines = (filteredEntries) => {
        let tmpRenderedItems = [];
        for (const [index, item] of filteredEntries.entries()) {
            if (!item.hide)
                tmpRenderedItems.push(
                    <div className="filter-item" key={index} onClick={()=>toggleFilter(index)}>
                        <div className={"filter-item-item" + (item.applied ? ' bold' : '')}>{item.label}</div>
                        <div className={"filter-item-count" + (item.applied ? ' bold' : '')}>{item.count}</div>
                    </div>
                );
        }
        setRenderedItems(tmpRenderedItems);
    }

    const filter = (e) => {
        let filterText = e.target.value;
        setFilterValue(filterText);//filterValue è disponibile in seguito
        filterItems(filterText);
    }

    const localExpanderHandler = (bodyWasShown, facetIndex) => {
        expanderHandler(bodyWasShown ? -1 : facetIndex)
    }

    return (
        <div className="facet">
            <p onClick={()=>localExpanderHandler(showBody, facetIndex)}>{title}</p>
            {showBody && (
                <div className="facet-body">
                {showFilter && (
                    <InputGroup className="mb-3">
                        <FormControl
                        name={title}
                        onChange={filter}
                        value={filterValue}
                        placeholder="type to filter"
                        />
                </InputGroup>
                )}
                <div className="filter-entries">
                    {renderedItems}
                </div>
            </div>
            )}
        </div>
    )
}

export default Facet
