import React, {useContext} from 'react'
import { Redirect, Route } from 'react-router-dom'
import UserContext from '../context/user/userContext'
import {useLocation} from "react-router-dom";

const PrivateRoute = ({...props}) => {

    const userContext = useContext(UserContext);

    console.log("La username vale " + userContext.username);

    const isLogged = userContext.username !== '';

    const location = useLocation();

    return (
        isLogged ? 
        <Route {...props} />
        : <Redirect to={{
            pathname: "/",
            search: location.search
          }} />
    )
}

export default PrivateRoute
