import React, {useEffect, useState, useCallback} from 'react'
import favoriteFull from '../../images/favorite_full.png'
import favoriteEmpty from '../../images/favorite_empty.png'
import downloadIcon from '../../images/download.png'

const PhotoDetailInfo = (props) => {

    const {photo, photoDetailChildren, toggleFavoriteHandler} = props;
    const [photoStated, setPhotoStated] = useState({myTrends: ""})
    const [infoJSX, setInfoJSX] = useState([])
    const [isFather, setIsFather] = useState(false);
    const [downloadLink, setDownloadLink] = useState('');
    const [fieldDefinitions, ] = useState([
        {
            label: "Brand",
            field: "brandDescription"
        },
        {
            label: "Season",
            field: "seasonType"
        },
        {
            label: "Date taken",
            field: "photoCallDate",
            dateField: true
        },
        {
            label: "Event/Fair",
            field: "photoCallEvent"
        },
        {
            label: "City",
            field: "photoCallCity"
        },
        {
            label: "Source",
            field: "photoCallCategoryLabel"
        },
        {
            label: "Gender",
            field: "gender"
        },
        {
            label: "Style/Construction",
            field: "productType"
        },
        {
            label: "Designer",
            field: "productDesigner"
        },
        {
            label: "Leathers by",
            field: "productLeathers"
        },
        {
            label: "Synthetics/Fabrics by",
            field: "productSynthetics"
        },
        {
            label: "Accessories",
            field: "productAccessories"
        },
        {
            label: "Soles/Heels by",
            field: "productSoles"
        }
    ])

    useEffect(() => {
        setPhotoStated(photo);
    }, [photo])

    const isFieldPresent = useCallback(
        (item) => {
            return (item && item !== "")
        },
        []
    )

    const formatDateField = useCallback(
        (value) => {
            const date = new Date(value)
            const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' }) 
            const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat.formatToParts(date) 

            return `${day} ${month} ${year }`
        },
        [],
    )

    useEffect(() => {
        setIsFather(photoDetailChildren.length > 0);
    }, [photoDetailChildren])

    useEffect(() => {
        let tmpArr = [];

        setDownloadLink(`${process.env.REACT_APP_BASE_URL}/photo/${isFather ? "downloadPhotosByFatherID" : "downloadPhotoByID"}/${photoStated.id}`);

        // eslint-disable-next-line
         for (const [index, field] of fieldDefinitions.entries()) {
            if (isFieldPresent(photoStated[field.field])) {
                tmpArr.push(
                    <div className="pdi-info" key={index}>
                        <div className="pdi-label">{field.label}</div>
                        <div className="pdi-value">{field.dateField ? formatDateField(photoStated[field.field]) : photoStated[field.field]}</div>
                    </div>
                )
            }
        }

        setInfoJSX(tmpArr);
    }, [photoStated, fieldDefinitions, isFieldPresent, formatDateField, isFather])

    /**
     * gestiamo localmente il cambio del favorito per l'icona. Utilizzerò un clone della foto altirmenti se modifico direttamente la foto qui si sballano i riferimenti
     * puntati con lo stato. Poichè uso una foto clonata devo introdurre anche una variabile di stato per la foto.
     */
    const localTogglefavoriteHandler = () => {
        let shouldAdd = Object.entries(photoStated.myTrends).length === 0;
        toggleFavoriteHandler(photoStated);
        //nessuna modifica a riferimenti della foto originale, clone, nuovo stato e update locale
        let clonedPhoto = {...photoStated, myTrends: shouldAdd ? {"id-fake": photoStated.id} : {}}
        setPhotoStated(clonedPhoto);
    }

    return (
        <div className="photo-detail-info">
            <div className="pdi-actions">
                <div className="pdi-download" key="action_1">
                    <a href={downloadLink}><img src={downloadIcon} alt="" width={32} /></a>
                </div>
                <div className="pdi-favorite" key="action_2">
                    <div className="favorite-button" onClick={localTogglefavoriteHandler}><img src={Object.entries(photoStated.myTrends).length === 0 ? favoriteEmpty : favoriteFull} alt=""/></div>
                </div>
            </div>
            <div className="photo-detail-info-content">
                {infoJSX}
            </div>
        </div>
    )
}

export default PhotoDetailInfo
