import React from 'react'
import favoriteFull from '../../images/favorite_full.png'
import favoriteEmpty from '../../images/favorite_empty.png'

const Photo = (props) => {

    const {photo, showDetailPhotoHandler, trialView, toggleFavoriteHandler} = props;

    return (
        <div className="photo">
            <img src={process.env.REACT_APP_PHOTO_BASE_URL + photo.thumbURL} onClick={()=>showDetailPhotoHandler(photo)} alt=""/>
            {!trialView && (<div className="favorite-button" onClick={()=>toggleFavoriteHandler(photo)}><img src={Object.entries(photo.myTrends).length === 0 ? favoriteEmpty : favoriteFull} alt=""/></div>)}
            {/* <div style={{position: "absolute", bottom: "0px", left: "0px", color: "white"}}><p style={{margin: "0px"}}>{photo.id}</p></div> */}
            {trialView && (
                <div style={{position: "absolute", bottom: "0px", left: "0px", "top": "0px", "right": "0px", color: "white"}}><img src="https://www.arstrends.com/service/img/arstrends/locked.png" alt=""/></div>
            )}
        </div>
    )
}

export default Photo
