import React from 'react';
import Container from 'react-bootstrap/Container';
import Login from '../login/Login'
import Footer from '../footer/Footer';

const LoginPage = () => {
    return (
        <Container fluid className="p-0 login-page min-vh-100 d-flex flex-column justify-content-center">
            <Login />
            <Footer />
        </Container>
    )
}

export default LoginPage
