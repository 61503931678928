import React, {useContext} from 'react'
import Spinner from 'react-bootstrap/Spinner'
import LoaderContext from '../../context/loader/loaderContext'

const Loader = () => {

    const loaderContext = useContext(LoaderContext);

    return (
        loaderContext.show ? 
        <div className="loader d-flex justify-content-center align-items-center">
            <Spinner animation="border" />
        </div>
        :
        <div></div>
    )
}


export default Loader
