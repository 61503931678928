import React, {useState, useContext, useEffect, useCallback} from 'react'
import Container from 'react-bootstrap/Container';
import MenuDetail from '../menu/MenuDetail'

import Footer from '../footer/Footer';
import PhotoDetail from '../photos/PhotoDetail';
import PhotoDetailInfo from '../photos/PhotoDetailInfo';
import PhotoDetailChildren from '../photos/PhotoDetailChildren';
import {Row, Col} from 'react-bootstrap';
import DataContext from '../../context/data/dataContext'


const Detail = (props) => {

    const {photoDetail, setPhotoDetail, toggleFavoriteHandler, showDetailPhoto, photoDetailChildren, photoList} = props;

    const [previousControlEnabled, setPreviousControlEnabled] = useState(true);
    const [nextControlEnabled, setNextControlEnabled] = useState(true);

    const dataContext = useContext(DataContext)

    
    /**
     * Determina l'enable/disable dei controlli previous/next di dettaglio
     * @param {*} selectedPhoto necessaria perchè photoDetail potrebbe non essere ancora disponibile
     */


    const calculateDetailControls = useCallback(
         (selectedPhoto) => {
            let checkStep = 0;
            while (checkStep < 2) {
                //console.log(`checkStep: ${checkStep}`)
                let found = false;
                let photoToCheck;
                photoToCheck = checkStep === 0 ? selectedPhoto : photoDetailChildren[0];
                //console.log(`photoToCheck: ${JSON.stringify(photoToCheck)}`)
                if (!photoToCheck) {
                    //questo capita solo nelle favorites quando rimuovi la preferita
                    setPreviousControlEnabled(false);
                    setNextControlEnabled(false);
                    break;
                }
                checkStep++;
                for (let index = 0; index < photoList.length; index++) {
                    let photo = photoList[index];
                    if (photo.id === photoToCheck.id) {
                        let previousValue = true;
                        let nextValue = true;
                        if (index === 0)
                            previousValue = false;
                        if (index === (photoList.length - 1))
                            nextValue = false;
                        setPreviousControlEnabled(previousValue);
                        setNextControlEnabled(nextValue);
                        found = true;
                        break;
                    }
                }
                if (found)
                    break;
            }
         },
         [photoList, photoDetailChildren],
     )


    useEffect(() => {

        calculateDetailControls(photoDetail);
        
    }, [photoDetail, calculateDetailControls])


    /**
     * utilizzata per cambiare vista nel dettaglio quando ci sono le foto figlie
     */
    const setDetailViewWithChild = (childPhoto) => {
        setPhotoDetail(childPhoto);
    }


    const slideDetail = (direction) => {

        let checkStep = 0;
        while (checkStep < 2) {
            let found = false;
            let photoToCheck;
            photoToCheck = checkStep === 0 ? photoDetail : photoDetailChildren[0];
            if (!photoToCheck) {
                //questo capita solo nelle favorites quando rimuovi la preferita
                setPreviousControlEnabled(false);
                setNextControlEnabled(false);
                break;
            }
            checkStep++;
            for (let index = 0; index < photoList.length; index++) {
                let photo = photoList[index];
                if (photo.id === photoToCheck.id) {
                    let newIndex = index + direction;
                    if (newIndex >= 0 && newIndex < photoList.length) {
                        dataContext.trackDetail(photo);
                        setPhotoDetail(photoList[newIndex]);
                        dataContext.loadChildren(photoList[newIndex].id);
                        calculateDetailControls(photoList[newIndex]);
                    }
                    found = true;
                    break;
                }
            }
            if (found)
                break;
        }
    }

    return (

        <Container fluid className="p-0 home-page h-100 d-flex flex-column">
            <MenuDetail showDetailPhotoHandler={showDetailPhoto}/>
            <Row className="no-gutters photo-detail-row">
                <div className="photo-detail-container">
                    <Row className="no-gutters">
                        <Col sm={6}><PhotoDetail photo={photoDetail} /></Col>
                        <Col sm={6}>
                            <Row className="no-gutters"><Col><PhotoDetailChildren photoDetailChildren={photoDetailChildren} setDetailViewWithChildHandler={setDetailViewWithChild} /></Col></Row>
                            <Row className="no-gutters mobile-detail-controls">
                                <button className={"previous-control " + (previousControlEnabled ? "" : "disabled")} onClick={()=>slideDetail(-1)}>PREVIOUS</button>
                                <button className={"next-control " + (nextControlEnabled ? "" : "disabled")} onClick={()=>slideDetail(1)}>NEXT</button>
                            </Row>
                            <Row className="no-gutters"><Col className="photo-detail-right-col"><PhotoDetailInfo photo={photoDetail} toggleFavoriteHandler={toggleFavoriteHandler} photoDetailChildren={photoDetailChildren} /></Col></Row>
                        </Col>
                    </Row>
                </div>
            </Row>
            <Row className="no-gutters desktop-detail-controls">
                <button className={"previous-control " + (previousControlEnabled ? "" : "disabled")} onClick={()=>slideDetail(-1)}>PREVIOUS</button>
                <button className={"next-control " + (nextControlEnabled ? "" : "disabled")} onClick={()=>slideDetail(1)}>NEXT</button>
            </Row>
            <Footer />
        </Container>

    )

}

export default Detail
