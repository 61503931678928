import React, {useState, useEffect, useContext} from 'react'
import {Card, Button, Alert, Form, Col, Row} from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import axios from 'axios'
import qs from 'qs'
import UserContext from '../../context/user/userContext'
import LoaderContext from '../../context/loader/loaderContext'
import logo from '../../images/logo.png'
import { Fragment } from 'react'
import {useLocation} from "react-router-dom";

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [alertLoginError, setAlertLoginError] = useState(false);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [hasCheckedAutoLogin, setHasCheckedAutoLogin] = useState(false);

    const userContext = useContext(UserContext);
    const loaderContext = useContext(LoaderContext);

    const location = useLocation();

    //forse meglio useLayoutEffect: https://stackoverflow.com/a/55768105/1723445
    useEffect(() => {
        async function userInfo() {
            loaderContext.showLoader(true);
            const res = await userInfoCall();
            if (res.email) {
                console.log("auto login successfull");
                userContext.updateUser(res.email, res.auths);
                enableIntercom(res);
                setRedirectToHome(true);
            } else {
                console.log("auto login didn't work");
                //valorizzare per abilitare il single sign on
                if (process.env.REACT_APP_ARSSUTORIA_SSO_URL) {
                    //window.location.href=process.env.REACT_APP_ARSSUTORIA_SSO_URL + "?cburl=" + encodeURIComponent(process.env.REACT_APP_FRONTEND_BASE_URL + location.search)
                    window.location.href=process.env.REACT_APP_ARSSUTORIA_SSO_URL + "?cburl=" + encodeURIComponent(location.search)
                    return
                }
            }
            setHasCheckedAutoLogin(true);
            loaderContext.showLoader(false);
        }
        userInfo();
        // eslint-disable-next-line
    }, []);//messo il [] per runnare alla componentDidMount

    const axiosTransport = axios.create({
        withCredentials: true,
        baseURL: process.env.REACT_APP_BASE_URL
    })

/*
user_id: '${sec.loggedInUserInfo(field:"id").toString()}',
        user_hash: '${userHash}',
        service_name: 'AT',
        <% if(user.expirationDate?.getTime()) { %>subscription_expirationDate_at: ${user.expirationDate.getTime()/1000},<% } %>
        subscription_type: '${user.getRole()}',
        email: '${user.email}',
        created_at: ${user.dateCreated.getTime()/1000},
        "widget": {
            "activator": "#IntercomPopup"
        },
        app_id: 'd4e8b46e7ef5fed232683254df3c03d2219193e0'
*/


    const enableIntercom = (userInfo) => {
        //console.log('accendo intercom');
        window.Intercom('boot', {
            user_hash: userInfo.userHash,
            service_name: 'AT',
            email: userInfo.email,
            user_id: userInfo.userId,
            created_at: userInfo.dateCreated,
            "widget": {
                "activator": "#IntercomPopup"
            },
            app_id: 'd4e8b46e7ef5fed232683254df3c03d2219193e0'
        });
    }

    const userInfoCall = async () => {

        try {
            const res = await axiosTransport({
                method: 'get',
                url: '/profile/getUserInfo'
            });

            //console.log('getUserInfo result:');
            //console.log(JSON.stringify(res.data));
            return res.data;
        } catch(e) {
            console.log('getUserInfo failed:');
            return {}
        }

    }

    const loginCall = async () => {

        const res = await axiosTransport({
            method: 'post',
            url: '/login/authAjax', 
            data: qs.stringify({
                j_username: username,
                j_password: password,
                _spring_security_remember_me: 'on'
            })
        });

        //console.log('login result:');
        //console.log(JSON.stringify(res.data));
        return res.data;

    }

    const login = async () => {

        loaderContext.showLoader(true);
        const res = await loginCall();

        if (res.success) {
            console.log('login successfull');
            const userInfo = await userInfoCall();
            if (userInfo.email) {
                userContext.updateUser(userInfo.email, userInfo.auths);
                enableIntercom(userInfo);
                setRedirectToHome(true);
            }
        } else {
            setAlertLoginError(true);
        }
        loaderContext.showLoader(false);
    }

    const enterListener = (event) => {
        if (event.key === "Enter") {
          login();
        }
      }

    return (
        !hasCheckedAutoLogin ? <></> :
            userContext.isAdmin() ? window.location = process.env.REACT_APP_ADMIN_URL :
            redirectToHome ? <Redirect to={{pathname: '/home', search: location.search}} /> :
            <Fragment>
            <Row className="no-gutters">
                <Col className="d-flex justify-content-center">
                    <img src={logo} alt="ArsTrends" className="login-logo mb-3" />
                </Col>
            </Row>
            <Row className="no-gutters">
                <Col xs={0} sm={3}></Col>
                <Col sm={6} xs={12}>
                    <Card className="login-box m-auto">
                        <Card.Body>
                            {alertLoginError && (
                                <Alert variant={'danger'}>
                                    Access Denied
                                </Alert>
                            )}
                            <Form>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="email" placeholder="Username" onInput={e => setUsername(e.target.value)} onKeyPress={enterListener} />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control type="password" placeholder="Password" onInput={e => setPassword(e.target.value)} onKeyPress={enterListener} />
                                </Form.Group>
                            </Form>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-sm-flex justify-content-around">
                                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                                    <Button variant="secondary" block onClick={() => document.location.href='https://www.arstrends.com'}>Free Trial</Button>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Button variant="success" block onClick={login}>Login</Button>
                                </div>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col xs={0} sm={3}></Col>
            </Row>
            </Fragment>
    )
}

export default Login
