import { UPDATE_USER, LOGOUT } from '../contextTypes'

export default (state, action) => {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                username: action.payload.username,
                roles: action.payload.roles
            }
        case LOGOUT:
            return {
                ...state,
                username: '',
                roles: []
            }
        default:
            return state;
    }
}