import { LOADER } from '../contextTypes'

export default (state, action) => {
    switch (action.type) {
        case LOADER:
            return {
                ...state,
                show: action.payload.show
            }
        default:
            return state;
    }
}