import React, {useState, useContext, useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Menu from '../menu/Menu'

import AppliedFacets from '../facets/AppliedFacets'
import Facets from '../facets/Facets'
import PhotoGrid from '../photos/PhotoGrid';
import Paginator from '../paginator/Paginator';
import Footer from '../footer/Footer';

import DataContext from '../../context/data/dataContext'
import UserContext from '../../context/user/userContext'
import {useLocation} from "react-router-dom";
import Detail from '../detail/Detail';

const HomePage = () => {

    const [photoDetail, setPhotoDetail] = useState({});//è la foto di dettaglio
    const [trialView, setTrialView] = useState(false);
    const [photoList, setPhotoList] = useState([])

    const dataContext = useContext(DataContext)
    const userContext = useContext(UserContext)

    const location = useLocation();


    
    useEffect(() => {
        /* 
        funziona ma gestito se nella url c'è la hash
        if (location.hash !== "") {
            location.setHash("")
            dataContext.loadData(location.hash.replace("#", ""), 1, true);
        } */
        if (location.state && location.state.productCategory) {
            console.log("da preferiti");
            dataContext.loadData(location.state.productCategory, 1, true);
        } else if (location.search && location.search !== '') {
            //magic link
            console.log("homepage esiste una search");
            let qs = location.search;
            if (qs.indexOf('productCategory') > -1) {
                //è una queryString
                console.log("è una queryString");
                dataContext.loadByQS(location.search);
            } else {
              //è un magic link
              console.log("è un magic link");
              dataContext.loadMagicLink(location.search);
            }
            
        } else {
            dataContext.loadData();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (dataContext.productCategory !== undefined) {
            //special case: ROLE_KID doesn't have 'S' at the end :(
            if (dataContext.productCategory === 'KID')
                setTrialView(!userContext.roles.includes(`ROLE_KID`));
            else
                setTrialView(!userContext.roles.includes(`ROLE_${dataContext.productCategory}S`));
        }
    }, [dataContext.productCategory, userContext.roles])

    useEffect(() => {
        setPhotoList(dataContext.data)
    }, [dataContext.data])

    const toggleMobileFacets = (show) => {
        document.querySelector('.facets').style.display = show ? 'unset' : 'none';
    }
    
    const changePageHandler = (page) => {
        dataContext.changePage(page);
    }

    /**
     * 
     * @param {*} photo : se presente è la foto da visualizzare nel dettaglio, altrimenti se vuoto significa tornare alla grid view
     */
    const showDetailPhoto = async (photo) => {
        if (!photo) {
            setPhotoDetail({});
        } else {
            dataContext.trackDetail(photo);
            await dataContext.loadChildren(photo.id);
            setPhotoDetail(photo);
        }
    }

   

    /**
     * Questo metodo è invocato al click su una singola voce di facet. La facet passata deve poi essere controllata con le applied per capire se è stata aggiunta o tolta
     * @param {*} facet 
     */
    const optionSelectorHandler = (facetCode, option) => {
        //console.log("Selezionata option " + option.label + " su " + facetCode);
        //se determino che il bottone è visible allora nascondo il menù...non bello, ma rimane il problema di capire via js che sei su mobile
        if (getComputedStyle(document.querySelector('.applied-facets .mobile-facets-toggle-button')).display === 'block')
            toggleMobileFacets(false);
        dataContext.toggleFacet(facetCode, option);
    }

    const toggleFavoriteHandler = (photo) => {
        dataContext.toggleFavorite(photo);
    }

  

    return (

        photoDetail.id === undefined ?

        <Container fluid className="p-0 home-page h-100 d-flex flex-column">
            <Menu productCategory={dataContext.productCategory} />
            <div className="homepage-content d-flex">
                <Facets toggleMobileFacetsHandler={toggleMobileFacets} facets={dataContext.facets} optionSelectorHandler={optionSelectorHandler}/>
                <div className="grid-container">
                    <AppliedFacets toggleMobileFacetsHandler={toggleMobileFacets} appliedFacets={dataContext.appliedFacets} optionSelectorHandler={optionSelectorHandler} />
                    <PhotoGrid photoList={photoList} showDetailPhotoHandler={showDetailPhoto} trialView={trialView} toggleFavoriteHandler={toggleFavoriteHandler} />
                    <Paginator count={dataContext.count} currentPage={dataContext.currentPage} changePageHandler={changePageHandler} /> 
                </div>
            </div>
            <Footer />
        </Container>

        :

        <Detail photoList={photoList} photoDetail={photoDetail} setPhotoDetail={setPhotoDetail} toggleFavoriteHandler={toggleFavoriteHandler} showDetailPhoto={showDetailPhoto} photoDetailChildren={dataContext.dataChildren}/>

    )
}

export default HomePage
