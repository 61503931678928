import React, { useReducer } from 'react';
import { LOADER } from '../contextTypes'
import LoaderReducer from './loaderReducer'
import LoaderContext from './loaderContext'

const LoaderState = props => {

    const initialState = {
      show: false
    }

    const [state, dispatch] = useReducer(LoaderReducer, initialState);

    const showLoader = (show) => {
        dispatch({
            type: LOADER,
            payload: {
                show: show
            }
        })
    }

    return (
        <LoaderContext.Provider
          value={{
            show: state.show,
            showLoader: showLoader
          }}
        >
          {props.children}
        </LoaderContext.Provider>
      );

}

export default LoaderState