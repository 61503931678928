/**
 * esegue la sola chiamata al server per i preferiti
 * @param {} photo 
 * @param {*} myTrendIdKey 
 * @param {*} axiosTransport 
 */
// eslint-disable-next-line
export const toggleFavoriteServerCall = async (photo, myTrendIdKey, axiosTransport) => {
    const isAdd = Object.entries(photo.myTrends).length === 0;

    let url = `/MyTrendPhoto/${isAdd ? "addPhotosMyWorkingTrend" : "removePhotoFromMyWorkingTrend"}?myTrendId=${myTrendIdKey}&photoId=${photo.id}`
    
    //console.log("managing favorite, calling " + url);

    try {
    // eslint-disable-next-line
    const res = await axiosTransport({
        method: 'get',
        url: url
    });
    return true;
    } catch(e) {
        console.error(`error while calling server for favorite ${isAdd ? "ADD" : "REMOVE"} photo id ${photo.id} with myTrends ${myTrendIdKey}`, e);
        return false;
    }

}

/**
 * Questo metodo ritorna il nuovo array di data (le foto di una griglia, homepage o preferiti) con cui aggiornare lo specifico context, lavorando di
 * shallow copy e update della solo fotografia che ha cambiato stato di preferito 
 * @param {} data : array originale del quale verrà fatta la shallow copy
 * @param {} photo 
 * @param {*} myTrendIdKey 
 * @param {*} removePhoto: se da favorites è rimozione 
 */
// eslint-disable-next-line
export const getUpdatedDataArray = (data, photo, myTrendIdKey, removePhoto = false) => {
    const isAdd = Object.entries(photo.myTrends).length === 0;

    //clone con shallow copy
    let newData = [...data];

    for (const [index, dataPhoto] of newData.entries()) {
        if (dataPhoto.id === photo.id) {
            //identificata la foto da aggiornare
            //console.log('identificata')
            let clonedPhoto = {...dataPhoto};

            //preparo il nuovo myTrends
            let newMyTrendsObject = {};
            if (isAdd) {
                newMyTrendsObject[myTrendIdKey] = photo.id;
            }

            //lo assegno
            clonedPhoto.myTrends = newMyTrendsObject;

            //rimpiazzo array
            if (!removePhoto)
                newData.splice(index, 1, clonedPhoto);
            else
                newData.splice(index, 1);

        }
    }

    return newData;
}
