import React, {useState, useEffect, useCallback} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap'
import Facet from './Facet'

const Facets = (props) => {

    const {toggleMobileFacetsHandler, facets, optionSelectorHandler} = props;

    const rebuildFacets = useCallback((expandFacetIndex = -1) => {

        const expanderHandler = (facetIndex) => {
            //è l'expander del body
            rebuildFacets(facetIndex)
        }

        let tmpFacets = [];
        let tmpFacetsJSX = [];

        Object
            .entries(facets)
            .forEach(([key, value]) => {
                //console.log(`${value.facetCode}: ${value.options.length} entries`);
                tmpFacets.push(value);
            });
        
        tmpFacets.sort(function(a,b) {
            return a.weight - b.weight
        });

        for (const [index, facet] of tmpFacets.entries()) {
            if (facet.options.length > 0)
                tmpFacetsJSX.push(<Facet key={index} facetIndex={index} facetCode={facet.facetCode} title={facet.label.toUpperCase()} items={facet.options} optionSelectorHandler={optionSelectorHandler} expanderHandler={expanderHandler} showBody={index === expandFacetIndex}/>);
        }

        setFacetJSX(tmpFacetsJSX);
    }, [facets, optionSelectorHandler]);

    
    const [facetJSX, setFacetJSX] = useState([]);

    useEffect(() => {
        // costruire il jsx
        // convertire in array l'oggetto
        // ordinare l'array
        // trattarlo
        rebuildFacets();

    }, [facets, rebuildFacets])

    

    return (
        <div className="facets">
            <div className="justify-content-between facet-mobile-header">
                <p className="facets-header">Filters</p>
                <Button className="close-btn" variant="outline-dark" size="sm" onClick={()=>toggleMobileFacetsHandler(false)}><FontAwesomeIcon icon={faTimes} /></Button>
            </div>
            {facetJSX}
        </div>
    )
}

export default Facets
