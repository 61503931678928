const productTitleMap = {
    BAG: 'BAGS',
    SHOE: 'SHOES',
    KID: 'KIDS SHOES',
    MATERIAL: 'MATERIALS'
}

const getTitleFromProductCategory = (productCategory) => {
    return productTitleMap[productCategory];
}

export default getTitleFromProductCategory;