import React from 'react'
import {SideBySideMagnifier} from "react-image-magnifiers";

const PhotoDetail = (props) => {

    const {photo} = props;

    return (
        <div className="photo-detail-photo" >
            <SideBySideMagnifier imageSrc={process.env.REACT_APP_PHOTO_BASE_URL + photo.zoomURL} 
            alwaysInPlace={true}
            />
        </div>
    )
}

export default PhotoDetail
